<template>
 <div class="wrapper">
    <div class="header-img">
      <input type="checkbox" id="input" hidden />
        <div class="box" id="jjjj">
          <div class="row justify-content-center">
            <label for="input" id="im">
              <div class="container acceso">
                <div class="row justify-content-center">
                  <div class="col">
                    <form>
                      <button type="button" @click="returnHome()" class="btn-close" aria-label="Close">X</button>
                      <h1 class="text text-center mt-5">Regístro de Taxistas<br>
                        
                      </h1>
                      <div class="form-group mt-4">
                          <label>Por favor, introduce los siguientes parámetros:</label>
                          <input class="form-control form-control-lg mt-3" type="text" v-model="phone" required placeholder="Teléfono" autocomplete="off">
                          <input class="form-control form-control-lg mt-3" type="text" v-model="license" required placeholder="Numero de licencia del taxi" autocomplete="off">
                          <input class="form-control form-control-lg mt-3" type="text" v-model="pin" required placeholder="PIN" autocomplete="off">
                      </div>
                        <p v-if="isInvalid === true" class="alert alert-warning">Parece que existe un error en los datos introducidos, por favor, inténtalo de nuevo.</p>   
                        <!--MOCK PARA PODER ACCEDER DE FORMA TEMPORAL-->
                        <button type="submit" class="btn btn-dark btn-lg btn-block mt-5 mb-5" @click="mock()">Obtener código</button>
                        <!--
                        <button type="submit" class="btn btn-dark btn-lg btn-block mt-5 mb-5" @click="loginTaxistas()">Obtener código</button>
                        -->
                    </form>
                   </div>
                </div>
              </div>
            </label>
          </div>
          <div class="container-fluid registro_taxistas_footer">
          <div class="row justify-content-center">
            <div class="col">
              <figure>
                <p class="col justify-content-left marca">
                  <a class="external_link mr-4" target="_blank" href="http://www.2kbo.com/cluf.html">Contrato de Licencia de Usuario Final</a>
                  <a class="external_link mr-4" target="_blank" href="http://www.2kbo.com/politicaprivacidad.html">Política de Privacidad</a>
                  <a class="external_link mr-4" target="_blank" href="http://www.2kbo.com/copyrightylicencia.html">Copyright y Licencia de software</a>
                  <a class="external_link mr-4" target="_blank" href="http://www.2kbo.com/licenciasdeterceros.html">Licencias de terceros</a>
                  <a class="external_link" target="_blank" href="http://www.2kbo.com/requerimientostecnicos.html">Requerimientos técnicos</a>
                </p>
                <p class="marca_kbo">© KBO 2021. Marca KBO. Versión 1.0</p>
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'RegistroTaxistasView',
    //ENVIAMOS DATOS
    data() {
    return {
      phone: '',
      license: '',
      pin: '',
      isInvalid: false,
      errorMsg: false
      }
    },
     
    methods: {
      mock() {
        if(this.phone === '671330007' && this.license === '9999' && this.pin === '4567' ) {
        this.$router.push('/input-taxistas')
        } 
        if(this.phone !== '671330007' && this.license !== '9999' && this.pin !== '4567' ) {
          this.isInvalid = true
          this.phone = '',
          this.license = '',
          this.pin = ''//Asi vacia los campos una vez enviada la informacion
        }
      },
      returnHome() {
        this.$router.push('/')
      },
      stayHere() {
        this.$router.push('/registro-taxistas')
      },
      async loginTaxistas() {
      console.log(this.phone);
      console.log(this.license);
      console.log(this.pin);
      //SI PHONE O LICENSE O PIN ESTAN VACIOS SALDRA VENTANA DE ERROR, CAMPOS REQUERIDOS
      if(this.phone === '' || this.license === '' || this.pin === '') {
          this.isInvalid = true
             
      //SI TODO ES CORRECTO EMITIMOS EL EVENTO LOGINDONE QUE SERA ESCUCHADO POR EL PADRE (APP) Y REDIRECCIONAMOS
      } else {
        (this.phone, this.license, this.pin)
        axios.post('http://localhost:3000/taxistas/', {
            phone: this.phone,
            license: this.license,
            pin: this.pin
            })
            //DE MOMENTO LA BBDD SIEMPRE NOS DEVUELVE UN 200, CUANDO ESTE HECHO EL BACKEND NOS DARA TRES ESTADOS, 
            //204 OK Y DOS ERRORES, 400 Y 403, POR LO QUE HABRA QUE MODIFICAR ESE IF A 204
            .then((response) => {
              if(response.status === 200) {
              this.$router.push('/input-taxistas')
              console.log(response);
              console.log(response.status);
              } else {
                 this.isInvalid = true
                }
              })
              this.phone = '',
              this.license = '',
              this.pin = ''//Asi vacia los campos una vez enviada la informacion
      }
    

      /**
        goToLogin() {
          if (this.response === true) {
            this.$router.push('/input-taxistas')
            console.log(this.response);
          } 
          if(this.response === false) {
            Swa1.fire({
                    title: 'Error, por favor, revise los parámetros introducidos',
                    icon: 'error',
                    confirmButtonText: 'Volver',
                    background: 'black',
                    confirmButtonColor: 'transparent',
                    showCancelButton: false,
                        willClose: () => {
                        location.assign("http://localhost:8080/#/registro-taxistas")
                        }
                    
                    }) 
            console.log(this.response);
          }
        },
        //FUNCION QUE COMPRUEBA QUE TODOS LOS CAMPOS REQUERIDOS EXISTEN    
        validatingDataRegister() {
                if(
                this.phone === '' ||
                this.license === ''||
                this.pin === ''
               ) { //comprobamos que no hay campos vacios
                    this.errorMsg = true
                    this.createUser = false;
                    Swa1.fire({
                    title: 'Por favor, rellene todos los campos',
                    icon: 'error',
                    confirmButtonText: 'Volver',
                    background: 'black',
                    confirmButtonColor: 'transparent',
                    showCancelButton: false,
                    })
                
                }  else {
                    this.errorMsg = false
                    this.createUser = true;
                    this.addNewUser()
                    }
        },        
            
        addNewUser() {
            if(this.createUser === true) {
                axios.post('http://localhost:3000/taxistas/', {
                phone: this.phone,
                license: this.license,
                pin: this.pin
                })
                .then((results) => {
                  if (results.status === 200) {
                  this.response = true
                  console.log(results);
                  } else this.response = false
                })
                .catch((error) => {
                    console.log(error)
                })
                this.createUser = false;
                this.phone = '',
                this.license = '',
                this.pin = ''//Asi vacia los campos una vez enviada la informacion para crear nuevo cliente
            } else {
                console.log('Yo no deberia estar aqui');
            }
        }
        */
    }
    
}
}

</script>

<style lang="scss" src="/home/hab8/Documents/KBO/2kbo-publicwebsite/src/scss/estilos.scss" scoped></style>

   